/*
  Project: Silicones Europe
  Author: Damian Nowak
 */

import Swiper, { Navigation, Autoplay, Grid, Thumbs, EffectFade } from 'swiper';
// Wersje powyżej 1.6.0 generują błędy
import '@lottiefiles/lottie-player';
import load from 'load-script';
import selectCustom from './modules/select-custom';
import accordionsBlock from './modules/accordion';
import readmoreShow from './modules/readmore';
import tooltip from './modules/tooltip';
import scrollTo from './modules/scrollto';
import movehand from './modules/movehand';
import child from './modules/child';
import popup from './modules/popup';
import popupContact from './modules/popup-contact';
import blockSliderThumbsText from './modules/blocks/block-slider-thumb-text-btn';
import blockSliderPosts from './modules/blocks/block-slider-posts';
import formPlaceholder from './modules/form';
import blockSliderTabs from './modules/blocks/block-slider-tabs';
import blockGridThumbs from './modules/blocks/block-grid-thumbs';
import { blockHeroVideo, blockHeroVideoScroll } from './modules/blocks/block-hero-video';
import blockTimelineNav from './modules/blocks/block-timeline-nav';
import blockTabsNav from './modules/blocks/block-tabs-nav';
import blockSliderSmallVideos from './modules/blocks/block-slider-small-videos';
import menuPosition from './modules/main-nav-children';
import menuShow from './modules/main-nav';
import hamburger from './modules/hamburger';
import blockHeadingPage from './modules/blocks/block-heading-page';
import isInViewport from './modules/isinviewport';
import up from './modules/up';
import hightlight from './modules/highlight';
import blockAnimation from './modules/blocks/block-animation';
import blockTable from './modules/blocks/block-table';
import blockInfographic from './modules/blocks/block-infographic';
import blockNumbersMap from './modules/blocks/block-numbers-map';

document.addEventListener('DOMContentLoaded', () => {
	const mobileBreakPointDown = 991.8;
	const mobileBreakpointUp = mobileBreakPointDown + 0.2;

	blockSliderThumbsText(Swiper, Navigation, Grid);

	blockSliderPosts(Swiper, Navigation);

	blockSliderTabs(Swiper, Navigation, Thumbs, EffectFade, scrollTo);

	blockGridThumbs(Swiper, Navigation, Autoplay, Grid);

	blockHeroVideo();
	blockHeroVideoScroll();

	blockTimelineNav(scrollTo);

	blockTabsNav(Swiper, Navigation);

	blockSliderSmallVideos(Swiper);

	blockHeadingPage(scrollTo, isInViewport, mobileBreakpointUp);

	blockAnimation();

	blockTable();

	blockInfographic(Swiper, Navigation);

	blockNumbersMap(load);

	formPlaceholder();
	accordionsBlock();
	readmoreShow(mobileBreakPointDown);
	tooltip();
	movehand();
	child();
	popup();
	document.addEventListener('htmlChange', popup);
	selectCustom();
	menuPosition(mobileBreakpointUp);
	menuShow(mobileBreakpointUp, menuPosition);
	hamburger();
	up(scrollTo, isInViewport);
	/* global word */
	/* eslint no-undef: "error" */
	hightlight(word);
	document.addEventListener('wpcf7mailsent', popupContact);
});
