const menuPosition = (breakpoint) => {
	const logo = document.querySelector('[data-main-nav-children-size]');
	const root = document.documentElement;
	const logoWidth = logo.offsetWidth;

	if (window.matchMedia(`(min-width: ${breakpoint}px)`).matches) {
		root.style.setProperty('--main-nav-children-width', `${logoWidth}px`);
	} else {
		root.style.setProperty('--main-nav-children-width', '100%');
	}
};

export default menuPosition;
