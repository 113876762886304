const up = (scrollto, see) => {
	const btn = document.querySelector('[data-up]');
	if (!btn) return;
	const header = document.querySelector('header');

	btn.onclick = () => {
		scrollto(document.querySelector('header'));
	};

	document.addEventListener('scroll', () => {
		if (!see(header)) {
			btn.classList.add('active');
		} else {
			btn.classList.remove('active');
		}
	});
};

export default up;
