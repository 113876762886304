const blockAnimation = () => {
	const zooms = document.querySelectorAll('[data-lottie-player-zoom]');
	if (!zooms) return;

	zooms.forEach((el) => {
		const zoom = el;
		zoom.onclick = () => {
			const player = zoom.previousElementSibling;
			zoom.classList.toggle('active');
			player.classList.toggle('active');
			setTimeout(() => {
				const lottie = player.querySelector('lottie-player');
				player.scrollLeft = (lottie.scrollWidth - player.clientWidth) / 2;
			}, 500);
		};
	});
};

export default blockAnimation;
