const hoverPlay = (slides) => {
	slides.forEach((element) => {
		const slide = element;
		slide.onmouseenter = () => {
			slide.querySelector('video').play();
		};
		slide.onmouseleave = () => {
			slide.querySelector('video').pause();
		};
	});
};

const blockSliderSmallVideos = (Swiper) => {
	const blockClass = '.js-swiper-ssv';
	const block = document.querySelector(blockClass);
	if (!block) return false;
	const { slides } = block.dataset;

	const swiper = new Swiper(block, {
		pagination: false,
		navigation: false,
		spaceBetween: 8,
		loop: true,
		slidesPerView: 1.5,
		grabCursor: true,
		centeredSlides: true,
		breakpoints: {
			992: {
				spaceBetween: 24,
				slidesPerView: slides,
				loop: false,
				centeredSlides: false,
			},
		},
		on: {
			init: (e) => {
				hoverPlay(e.slides);
			},
		},
	});
	return swiper;
};

export default blockSliderSmallVideos;
