const blockHeroVideo = () => {
	const blockAction = document.querySelector('.js-hv-action');
	if (!blockAction) return;

	blockAction.onclick = () => {
		const video = blockAction.closest('section').querySelector('video');
		if (video.paused) {
			video.play();
		} else {
			video.pause();
		}
		blockAction.classList.toggle('active');
	};
};

const blockHeroVideoScroll = () => {
	const blockScroll = document.querySelector('.js-hv-scroll');
	if (!blockScroll) return;
	blockScroll.style.marginBottom = 0;

	window.onscroll = () => {
		const margin = window.scrollY > 240 ? 240 : window.scrollY;
		const video = blockScroll.querySelector('video');
		if (blockScroll.style.marginBottom !== margin) {
			blockScroll.style.marginBottom = `${margin * -1}px`;
		}

		if (window.scrollY > 240) {
			video.pause();
		} else {
			video.play();
		}
	};
};
export { blockHeroVideo, blockHeroVideoScroll };
