const readmoreShow = (breakpoint) => {
	const buttons = document.querySelectorAll('[data-readmore]');
	if (!buttons) return;
	buttons.forEach((el) => {
		const btn = el;
		btn.onclick = () => {
			const { readmore } = btn.dataset;
			const mobile = window.matchMedia(`(max-width: ${breakpoint}px)`);
			if (!mobile.matches) return;
			const readmoreRoot = btn.closest(`.${readmore}`);
			const content = readmoreRoot.querySelector('[data-readmore-content]');
			content.classList.toggle(content.dataset.readmoreContent);
			btn.classList.toggle('active');
			// if (content.classList.contains('d-none')) {
			// } else {
			// 	content.classList.toggle('hidden-part');
			// }
			// btn.remove();
		};
	});
};

export default readmoreShow;
