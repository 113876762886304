const selectCustom = () => {
	const options = document.querySelectorAll('[data-option]');
	options.forEach((el) => {
		const option = el;
		option.onclick = () => {
			if (!option.classList.contains('selected')) {
				option.parentNode.querySelector('[data-option].selected').classList.remove('selected');
				option.classList.add('selected');
				option.closest('[data-select]').querySelector('[data-select-value]').textContent = option.textContent;
				option.closest('[data-select]').querySelector('select').value = option.textContent;
			}
		};
	});

	const selects = document.querySelectorAll('[data-select]');
	selects.forEach((el) => {
		const select = el;
		select.onclick = () => {
			select.classList.toggle('open');
		};
	});

	window.addEventListener('click', (e) => {
		selects.forEach((el) => {
			const select = el;
			if (!select.contains(e.target)) {
				select.classList.remove('open');
			}
		});
	});

	document.addEventListener(
		'wpcf7submit',
		(e) => {
			const selectCustomPlaceholder = e.target.querySelector('[data-select-value]');
			selectCustomPlaceholder.innerHTML = `<span class="c-form__select-placeholder">${selectCustomPlaceholder.dataset.selectValue}</span>`;
		},
		false,
	);
};

export default selectCustom;
