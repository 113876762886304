const hightlight = (word) => {
	if (word !== '') {
		const text = document.querySelector('main').innerHTML;
		const re = new RegExp(word, 'g'); // search for all instances
		const newText = text.replace(re, `<mark>${word}</mark>`);
		document.querySelector('main').innerHTML = newText;
	}
};

export default hightlight;
