const blockGridThumbs = (Swiper, Navigation, Autoplay, Grid) => {
	const blockClass = '.js-swiper-grid-thumbs';
	const blocks = document.querySelectorAll(blockClass);
	if (!blocks) return false;

	blocks.forEach((block) => {
		const swiper = new Swiper(block, {
			modules: [Navigation, Autoplay, Grid],
			pagination: false,
			spaceBetween: 8,
			loop: false,
			slidesPerView: 1.06,
			navigation: false,
			autoplay: true,
			grid: {
				fill: 'row',
				rows: 1,
			},
			breakpoints: {
				992: {
					slidesPerView: 2,
					spaceBetween: 32,
					grid: {
						fill: 'row',
						rows: 2,
					},
				},
			},
		});
		return swiper;
	});

	return false;
};

export default blockGridThumbs;
