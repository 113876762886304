const accordionShow = (content, type) => {
	content.classList.toggle('active');

	const fn = {
		normal: () => {
			content.nextElementSibling.classList.toggle('active');
		},
		id: () => {
			content.classList.add('unactive');
			const { accordionId } = content.dataset;
			const contentId = document.querySelector(`[data-accordion-content="${accordionId}"]`);
			contentId.classList.toggle('active');
		},
		hide: () => {
			const { accordionId } = content.dataset;
			const contentId = document.querySelector(`[data-accordion-content="${accordionId}"]`);
			contentId.classList.toggle('active');
			contentId.previousElementSibling.querySelector('.unactive').classList.remove('unactive', 'active');
		},
		mobile: () => {
			if (window.matchMedia('(max-width: 991.98px)').matches) {
				fn.normal();
			}
		},
	};
	fn[type]();
};

const accordionsBlock = () => {
	const accordions = document.querySelectorAll('[data-accordion-btn]');
	if (accordions) {
		accordions.forEach((el) => {
			const accordion = el;
			accordion.onclick = () => {
				const version = accordion.dataset.accordionBtn;
				accordionShow(accordion, version);
				return false;
			};
		});
	}
};

export default accordionsBlock;
