const hamburger = () => {
	const menuBtn = document.querySelector('[data-hamburger]');
	const nav = document.querySelector('nav');
	if (!nav) return;
	menuBtn.onclick = () => {
		menuBtn.classList.toggle('active');
		nav.classList.toggle('active');
		document.documentElement.classList.toggle('c-hamburger--open');
	};
};

export default hamburger;
