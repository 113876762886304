const movehand = () => {
	const observerOptions = {
		rootMargin: '0px',
		threshold: 0.5,
	};

	const observer = new IntersectionObserver((elements) => {
		elements.forEach((el) => {
			const element = el;
			let { media } = el.target.dataset;
			if (!media) media = 991.8;
			if (element.isIntersecting && window.matchMedia(`(max-width: ${media}px)`).matches) {
				element.target.classList.add('active');
				observer.unobserve(element.target);
				setTimeout(() => {
					element.target.remove();
				}, 2000);
			}
		});
	}, observerOptions);

	const blocks = document.querySelectorAll('.js-movehand');
	blocks.forEach((block) => {
		observer.observe(block);
	});
};

export default movehand;
