const menuShow = (breakpoint, position) => {
	const items = document.querySelectorAll('[data-main-nav]');
	const childrens = document.querySelectorAll('[data-main-nav-children]');

	items.forEach((el) => {
		const item = el;
		item.onmouseenter = () => {
			position(breakpoint);
		};
		item.onclick = () => {
			const media = window.matchMedia(`(min-width: ${breakpoint}px`).matches;
			if (media) return;
			const { mainNav } = item.dataset;
			const navChildren = document.querySelector(`[data-main-nav-children="${mainNav}"]`);

			childrens.forEach((elChild) => {
				const children = elChild;
				children.classList.remove('active');
				children.closest('section').classList.remove('active');
			});

			items.forEach((elItem) => {
				const itemactive = elItem;
				if (item !== itemactive) {
					itemactive.classList.remove('active');
				}
			});

			if (item.classList.contains('active')) {
				navChildren.classList.remove('active');
				navChildren.closest('section').classList.remove('active');
				item.classList.remove('active');
			} else {
				navChildren.classList.add('active');
				navChildren.closest('section').classList.add('active');
				item.classList.add('active');
			}
		};
	});
};

export default menuShow;
