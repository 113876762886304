const popup = () => {
	const popupCloseBtn = document.querySelectorAll('[data-popup-close]');

	if (popupCloseBtn) {
		popupCloseBtn.forEach((el) => {
			const btn = el;
			btn.onclick = (e) => {
				e.preventDefault();
				btn.closest('[data-popup-root]').classList.remove('d-flex');
			};
		});
	}

	const popupOpenBtn = document.querySelectorAll('[data-popup-open]');
	if (popupOpenBtn) {
		popupOpenBtn.forEach((el) => {
			const btn = el;
			btn.onclick = (e) => {
				e.preventDefault();
				// console.log(document.querySelector(`[data-popup-root="${btn.dataset.popupOpen}]`));
				document.querySelector(`[data-popup-root="${btn.dataset.popupOpen}"]`).classList.add('d-flex');
			};
		});
	}
};

export default popup;
