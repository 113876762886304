const blockSliderTabs = (Swiper, Navigation, Thumbs, EffectFade, scrollTo) => {
	const blocks = document.querySelectorAll('.js-swiper-st');
	if (!blocks) return;

	const classRoot = '.js-st-root';

	blocks.forEach((el) => {
		const block = el;
		const { version, slides, slidesMobile } = block.dataset;
		let slidesPerViewMobile = 1;

		if (slidesMobile) {
			slidesPerViewMobile = slidesMobile;
		} else if (version) {
			slidesPerViewMobile = 2.3;
		} else {
			slidesPerViewMobile = 2.83;
		}
		const blockNav = block.closest(classRoot).querySelector('.js-swiper-st-nav');
		const nav = new Swiper(blockNav, {
			modules: [Navigation, Thumbs, EffectFade],
			spaceBetween: 0,
			slidesPerView: slidesPerViewMobile,
			freeMode: true,
			watchSlidesProgress: true,
			loop: false,
			breakpoints: {
				992: {
					slidesPerView: slides,
				},
			},
		});
		const content = new Swiper(block, {
			modules: [Navigation, Thumbs, EffectFade],
			effect: 'fade',
			loop: false,
			fadeEffect: {
				crossFade: true,
			},
			spaceBetween: 0,
			navigation: {
				nextEl: '.js-swiper-st-next',
				prevEl: '.js-swiper-st-prev',
			},
			thumbs: {
				swiper: nav,
			},
			autoHeight: version ? 1 : 0,
			breakpoints: {
				992: {
					allowTouchMove: false,
				},
			},
			on: {
				realIndexChange: (e) => {
					if (!version) return;
					const label = e.el.closest(classRoot).querySelector('.js-swiper-st-number');
					label.textContent = e.realIndex + 1;
				},
				slideChange: (e) => {
					const mobile = window.matchMedia('(max-width: 991.98px)');
					if (!mobile.matches) return;
					scrollTo(e.el.closest(classRoot));
				},
			},
		});

		return [content, nav];
	});
};

export default blockSliderTabs;
