const blockSliderThumbsText = (Swiper, Navigation, Grid) => {
	const blockClass = '.js-swiper-thumbs-text';
	const blocks = document.querySelectorAll(blockClass);
	if (!blocks) return false;

	const blockRoot = '.js-root-sttb';
	const swiperSize = (block) => {
		const width = window.innerWidth;
		const slider = block;
		const pos = slider.$el[0].getBoundingClientRect();

		slider.$el[0].style.marginRight = `${pos.x * -1}px`;
		if (width < 1024) slider.$el[0].style.marginRight = 'auto';
	};

	blocks.forEach((block) => {
		const { version, slides } = block.dataset;

		const swiper = new Swiper(block, {
			modules: [Navigation, Grid],
			pagination: false,
			spaceBetween: 0,
			slidesPerGroupSkip: 1,
			loop: false,
			grid: version
				? {
						rows: 3,
				  }
				: false,
			navigation: {
				nextEl: '.js-sttb-next',
				prevEl: '.js-sttb-prev',
			},
			breakpoints: {
				992: {
					slidesPerView: version ? 2.63 : slides,
					spaceBetween: 32,
					loop: false,
					grid: false,
				},
			},
			on: {
				init: (s) => {
					if (!version) return;
					swiperSize(s);
				},
				resize: (s) => {
					if (!version) return;
					swiperSize(s);
				},
				touchEnd: (s, e) => {
					if (!version) return;
					const media = window.matchMedia('(max-width: 991.98px)');
					const thumb = '.js-sttb-thumb';
					if (media.matches) {
						e.target.closest(thumb).classList.toggle('active');
					} else {
						e.target.closest(thumb).classList.remove('active');
					}
				},
				realIndexChange: (e) => {
					if (version) return;
					const label = e.el.closest(blockRoot).querySelector('.js-swiper-sttb-number');
					label.textContent = e.realIndex ? e.realIndex + 1 : 1;
				},
			},
		});
		return swiper;
	});

	return false;
};

export default blockSliderThumbsText;
