import scrollTo from '../scrollto';

const breakpoint = 991.8;
/* global google, googleMapsApiKey */

function initMarker(marker, map, div) {
	const markerEl = marker;
	const { markerLat, markerLng, markerGroup, markerName, markerDesc, markerColor } = markerEl.dataset;
	let { markerColorAnother } = markerEl.dataset;
	if (!markerColorAnother) {
		markerColorAnother = markerColor;
	}
	const svgDataOff = `
	<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
	<circle cx="12.6094" cy="12.7812" r="12" fill="${markerColor}"/>
	<path d="M24.6094 12.7812C24.6094 19.4087 19.2368 24.7812 12.6094 24.7812C12.6094 24.7812 12.6122 0.78125 12.6094 0.78125C19.2368 0.78125 24.6094 6.15383 24.6094 12.7812Z" fill="${markerColorAnother}"/>
	</svg>
    `;

	const svgDataOn = `
	<svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g filter="url(#filter0_d_2762_49390)">
	<circle cx="45" cy="43" r="37" fill="${markerColor}"/>
	<path d="M82 43C82 63.4345 65.4345 80 45 80C45 80 45.0087 6 45 6C65.4345 6 82 22.5655 82 43Z" fill="${markerColorAnother}"/>
	<path d="M53.3954 28.5277C51.0979 26.2302 48.0429 24.9648 44.7936 24.9648C41.5442 24.9648 38.4893 26.2302 36.1918 28.5277C33.8943 30.8256 32.6289 33.8802 32.6289 37.1295C32.6289 46.5525 38.551 53.4949 44.7936 60.6217C50.1444 54.3867 56.9583 46.5525 56.9583 37.1295C56.9583 33.8802 55.6929 30.8256 53.3954 28.5277ZM44.7936 32.6441C47.2667 32.6441 49.2791 34.6565 49.2791 37.1295C49.2791 39.603 47.2667 41.615 44.7936 41.615C42.3205 41.615 40.3081 39.603 40.3081 37.1295C40.3081 34.6565 42.3205 32.6441 44.7936 32.6441Z" stroke="white" stroke-width="4"/>
	</g>
	<defs>
	<filter id="filter0_d_2762_49390" x="0" y="0" width="90" height="90" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
	<feFlood flood-opacity="0" result="BackgroundImageFix"/>
	<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
	<feOffset dy="2"/>
	<feGaussianBlur stdDeviation="4"/>
	<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
	<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2762_49390"/>
	<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2762_49390" result="shape"/>
	</filter>
	</defs>
	</svg>
    `;

	const svgOff = {
		url: `data:image/svg+xml;charset=utf-8, ${encodeURIComponent(svgDataOff)} `,
		anchor: new google.maps.Point(0, 0),
	};

	const svgOn = {
		url: `data:image/svg+xml;charset=utf-8, ${encodeURIComponent(svgDataOn)} `,
		anchor: new google.maps.Point(37, 55),
	};

	const latLng = {
		lat: parseFloat(markerLat),
		lng: parseFloat(markerLng),
	};

	const markerNew = new google.maps.Marker({
		position: latLng,
		map,
		title: markerName,
		icon: svgOff,
	});

	map.markers.push(markerNew);

	if (markerDesc) {
		const contentString = `
		<div class="b-numbers-map__map-info-group">${markerGroup}</div>
		<div class="b-numbers-map__map-info-name u-font-text"> ${markerName} </div>
		<div class="b-numbers-map__map-info-desc">${JSON.parse(markerDesc)}</div>`;

		const infowindow = new google.maps.InfoWindow({
			content: contentString,
			maxWidth: 178,
			minWidth: 150,
			pixelOffset: new google.maps.Size(0, 9),
		});

		markerEl.onmouseover = () => {
			markerNew.setIcon(svgOn);
			infowindow.open(map, markerNew);
			if (!window.matchMedia(`(max-width: ${breakpoint}px`).matches) return;

			scrollTo(div, true);
		};
		markerEl.onmouseout = () => {
			markerNew.setIcon(svgOff);
			infowindow.close(map, markerNew);
		};

		google.maps.event.addListener(markerNew, 'click', () => {
			marker.classList.add('hover');
			infowindow.open(map, markerNew);
			markerNew.setIcon(svgOn);
		});

		google.maps.event.addListener(infowindow, 'closeclick', () => {
			marker.classList.remove('hover');
			// infowindow.close(map, markerNew);
			markerNew.setIcon(svgOff);
		});
	}
}

function centerMap(map) {
	const bounds = new google.maps.LatLngBounds();
	map.markers.forEach((marker) => {
		bounds.extend({
			lat: marker.position.lat(),
			lng: marker.position.lng(),
		});
	});

	if (map.markers.length === 1) {
		map.setCenter(bounds.getCenter());
	} else {
		map.fitBounds(bounds);
	}
}

export function initMap() {
	const mapInit = document.querySelector('[data-numbers-map-init]');
	const mapRoot = document.querySelector('[data-numbers-map-root]');
	const markers = mapRoot.querySelectorAll('[data-marker]');
	const styles = [
		{
			featureType: 'administrative',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#444444',
				},
			],
		},
		{
			featureType: 'landscape',
			elementType: 'all',
			stylers: [
				{
					color: '#F2F2F2',
				},
			],
		},
		{
			featureType: 'poi',
			elementType: 'all',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'road',
			elementType: 'all',
			stylers: [
				{
					saturation: -100,
				},
				{
					lightness: 45,
				},
			],
		},
		{
			featureType: 'road.highway',
			elementType: 'all',
			stylers: [
				{
					visibility: 'simplified',
				},
			],
		},
		{
			featureType: 'road.arterial',
			elementType: 'labels.icon',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'transit',
			elementType: 'all',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'water',
			elementType: 'all',
			stylers: [
				{
					color: '#35A8E0',
				},
				{
					visibility: 'on',
				},
				{
					saturation: '0',
				},
			],
		},
		{
			featureType: 'water',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#7399C4',
				},
			],
		},
	];
	const mapArgs = {
		zoom: 16,
		zoomControl: true,
		mapTypeControl: false,
		scaleControl: true,
		streetViewControl: true,
		rotateControl: false,
		fullscreenControl: true,
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		styles,
	};
	const map = new google.maps.Map(mapInit, mapArgs);

	map.markers = [];
	markers.forEach((el) => {
		const marker = el;
		initMarker(marker, map, mapInit);
	});

	centerMap(map);

	// Return map instance.
	// return map;
}

const blockNumbersMap = (load) => {
	if (typeof googleMapsApiKey === 'undefined') return;
	window.initMap = initMap;
	load(`https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&callback=initMap&v=weekly`, (err) => {
		if (err) return;

		const overflow = document.querySelector('[data-numbers-map-overflow]');
		const overflowBtns = document.querySelectorAll('[data-numbers-map-overflow-btn]');
		const overflowTotal = overflow.scrollHeight - overflow.offsetHeight;
		const overflowCounter = 50;
		const overflowClass = 'unactive';
		if (overflowTotal === 0) {
			overflowBtns[1].parentElement.classList.add('d-none');
		}

		overflowBtns.forEach((el) => {
			const btn = el;
			const { numbersMapOverflowBtn } = btn.dataset;
			btn.onclick = () => {
				if (overflow.scrollTop === 0 && numbersMapOverflowBtn === 'up') {
					btn.classList.add(overflowClass);
				}
				if (overflowTotal - overflow.scrollTop < 8 && numbersMapOverflowBtn === 'down') {
					btn.classList.add(overflowClass);
				}

				if (numbersMapOverflowBtn === 'up') {
					overflow.scrollTop -= overflowCounter;
					overflowBtns[1].classList.remove(overflowClass);
				} else {
					overflow.scrollTop += overflowCounter;
					overflowBtns[0].classList.remove(overflowClass);
				}
			};
		});
	});
};

export default blockNumbersMap;
