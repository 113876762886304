const blockTable = () => {
	const tables = document.querySelectorAll('[data-table]');
	tables.forEach((el) => {
		const total = el.scrollWidth - el.offsetWidth;
		if (total <= 0) {
			const hand = el.closest('[data-section]').querySelector('.js-movehand');
			if (!hand) return;
			hand.remove();
		}
	});
};

export default blockTable;
