const blockTabsNav = (Swiper, Navigation) => {
	const blockClass = '.js-swiper-tabs-nav';
	const block = document.querySelector(blockClass);
	if (!block) return false;
	const { slides } = block.dataset;

	const swiper = new Swiper(block, {
		modules: [Navigation],
		pagination: false,
		spaceBetween: 0,
		loop: false,
		slidesPerView: slides,
		// roundLengths: true,
		navigation: {
			nextEl: '.js-swiper-tn-next',
			prevEl: '.js-swiper-tn-prev',
		},
	});
	return swiper;
};

export default blockTabsNav;
