const blockSliderPosts = (Swiper, Navigation) => {
	const blockClass = '.js-swiper-slide-posts';
	const blocks = document.querySelectorAll(blockClass);
	if (!blocks) return false;

	blocks.forEach((block) => {
		const swiper = new Swiper(block, {
			modules: [Navigation],
			pagination: false,
			spaceBetween: 0,
			loop: false,
			slidesPerView: 1,
			navigation: {
				nextEl: '.js-sp-next',
				prevEl: '.js-sp-prev',
			},
			on: {
				realIndexChange: (e) => {
					const label = e.el.parentElement.querySelector('.js-swiper-sp-number');
					label.textContent = e.realIndex + 1;
				},
			},
		});
		return swiper;
	});

	return false;
};

export default blockSliderPosts;
