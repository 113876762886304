const show = (element, action = false) => {
	const change = {
		remove: (rects) => {
			rects.forEach((el) => {
				el.classList.remove('active');
			});
		},
		add: (rects) => {
			rects.forEach((el) => {
				el.classList.toggle('active');
			});
		},
	};
	const { infographicId } = element.dataset;
	const rects = document.querySelectorAll(`[id="${infographicId}"]`);
	if (!rects) return;

	if (action) {
		change.remove(rects);
	} else {
		change.add(rects);
	}
};

const carousel = (Swiper, Navigation) => {
	const blockClass = '.js-swiper-infographic';
	const blocks = document.querySelectorAll(blockClass);
	if (!blocks) return false;

	blocks.forEach((block) => {
		const swiper = new Swiper(block, {
			modules: [Navigation],
			pagination: false,
			spaceBetween: 0,
			loop: false,
			slidesPerView: 1,
			autoHeight: true,
			navigation: {
				nextEl: '.js-swiper-infographic-next',
				prevEl: '.js-swiper-infographic-prev',
			},
			on: {
				realIndexChange: (e) => {
					const labels = e.el.parentElement.querySelectorAll('.js-swiper-infographic-number');
					labels.forEach((el) => {
						const label = el;
						label.textContent = e.realIndex + 1;
					});
				},
			},
		});
		return swiper;
	});

	return false;
};

const blockInfographic = (Swiper, Navigation) => {
	const infographics = document.querySelectorAll('[data-infographic-id]');

	infographics.forEach((el) => {
		const graphic = el;
		// graphic.onclick = () => {
		// 	if (window.matchMedia(`(min-width: ${breakpoint}px)`).matches) return;
		// 	show(graphic);
		// };

		graphic.onmouseenter = () => {
			// if (!window.matchMedia(`(min-width: ${breakpoint}px)`).matches) return;
			show(graphic);
		};
		graphic.onmouseleave = () => {
			// if (!window.matchMedia(`(min-width: ${breakpoint}px)`).matches) return;
			show(graphic, true);
		};
	});

	carousel(Swiper, Navigation);
};

export default blockInfographic;
