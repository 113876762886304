const container = 1440 - 0.02;

const unactive = () => {
	const steps = document.querySelectorAll('[data-anchor]');
	steps.forEach((elStep) => {
		const step = elStep;
		step.classList.remove('active');
	});
};

const blockTimelineNav = (animate) => {
	const steps = document.querySelectorAll('[data-anchor]');

	const observerOptions = {
		rootMargin: '0px 0px -85% 0px',
		threshold: 0,
	};

	const observer = new IntersectionObserver((els) => {
		els.forEach((el) => {
			if (el.isIntersecting) {
				unactive();
				const anchor = document.querySelector(`[data-anchor="${el.target.dataset.anchorContent}"]`);
				anchor.classList.add('active');
				if (window.matchMedia(`(max-width: ${container}px`).matches) {
					anchor.scrollIntoView();
				}
			}
		});
	}, observerOptions);

	steps.forEach((elBtn) => {
		const btn = elBtn;
		const content = document.querySelector(`[data-anchor-content="${btn.dataset.anchor}"]`);

		btn.onclick = () => {
			unactive();

			animate(content);
			// btn.classList.add('active-click');
		};

		// resize(btn);
		observer.observe(content);
	});
};

export default blockTimelineNav;
