const child = () => {
	const tags = document.querySelectorAll('.child');

	if (!tags) return;

	tags.forEach((els) => {
		const tag = els;
		tag.parentElement.classList.add('parent');
	});
};

export default child;
