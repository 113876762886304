const formPlaceholder = () => {
	const inputsText = document.querySelectorAll('.wpcf7-text');
	inputsText.forEach((text) => {
		const input = text;
		input.onfocus = () => {
			input.parentElement.nextElementSibling.classList.add('focus');
		};
		input.onblur = () => {
			if (!input.value) {
				input.parentElement.nextElementSibling.classList.remove('focus');
			}
		};
	});
};

export default formPlaceholder;
