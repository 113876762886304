const methods = {
	classActive: 'active',
	classHover: 'hover',
	unactive(el, type = 'parent') {
		if (type === 'parent') {
			el.parentElement.classList.remove(this.classActive);
			return;
		}
		el.classList.remove(this.classActive);
	},
	active(el, type = 'parent') {
		if (type === 'parent') {
			el.parentElement.classList.add(this.classActive);
			return;
		}
		el.classList.add(this.classActive);
	},
	unhover(el) {
		el.classList.remove(this.classHover);
	},
	hover(el) {
		el.classList.add(this.classHover);
	},
};

const blockHeadingPage = (scrollTo, isInViewport, breakpoint) => {
	const tableFixed = document.querySelector('[data-table-contents-fixed]');
	if (!tableFixed) return;
	const tableHeight = tableFixed.offsetHeight;

	if (tableHeight) {
		document.documentElement.style.setProperty('--table-contents-offset', `-${tableHeight}px`);
	}

	if (window.matchMedia(`(min-width: ${breakpoint}px)`).matches) {
		tableFixed.onmouseenter = () => {
			methods.hover(tableFixed);
		};

		tableFixed.onmouseleave = () => {
			methods.unhover(tableFixed);
		};
	}

	const tableBtn = tableFixed.querySelector('[data-table-contents-btn]');
	if (tableBtn) {
		tableBtn.onclick = () => {
			tableFixed.classList.toggle(methods.classHover);
		};
	}

	const tableStatic = document.querySelector('[data-table-contents-static]');
	if (tableStatic) {
		document.addEventListener(
			'scroll',
			() => {
				const visible = isInViewport(tableStatic);
				if (visible) {
					methods.unactive(tableFixed, 'normal');
				} else {
					methods.active(tableFixed, 'normal');
				}
				methods.unhover(tableFixed);
			},
			{ passive: true },
		);
	}

	const anchors = document.querySelectorAll('[data-table-contents-lists] a[href^="#"]');
	if (!anchors) return;

	const observerOptions = {
		rootMargin: '0px 0px -85% 0px',
		threshold: 0,
	};

	const observer = new IntersectionObserver((els) => {
		els.forEach((el) => {
			if (el.isIntersecting) {
				anchors.forEach((elAnchor) => {
					methods.unactive(elAnchor);
				});
				const items = document.querySelectorAll(`[data-table-contents-lists] a[href="#${el.target.id}"]`);
				items.forEach((elItems) => {
					methods.active(elItems);
				});
			}
		});
	}, observerOptions);

	anchors.forEach((el) => {
		const anchor = el;
		const href = anchor.getAttribute('href');
		anchor.onclick = () => {
			methods.unhover(tableFixed, 'normal');
			scrollTo(document.querySelector(href));
		};

		const anchorDiv = document.querySelector(href);
		if (anchorDiv) {
			observer.observe(anchorDiv);
		}
	});
};

export default blockHeadingPage;
