const tooltip = () => {
	const tooltipBtn = document.querySelectorAll('[data-tooltip]');
	tooltipBtn.forEach((el) => {
		const btn = el;
		btn.onclick = () => {
			btn.classList.toggle('active');
		};
	});
};
export default tooltip;
